<template>
    <company-module-component/>
</template>

<script>
    import CompanyModuleComponent from "@/components/admin/CompanyModuleComponent";
    
    export default {
        name: "Company",
        title: "Datos de la Organización | Equity Baja",
        components: {
            CompanyModuleComponent,
        }
    }
</script>

<style scoped>

</style>